<template>
  <view-base ref="base">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <slot name="title">
                -
              </slot>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <slot>-</slot>
    </div>
  </view-base>
</template>

<script>
import ViewBase from '@/views/ViewBase.vue'

export default {
  name: 'AdminBase',
  components: {
    ViewBase,
  },
  data() {
    return {
      title: 'Default Title',
    }
  },
  methods: {
    goBack() {
      this.$refs.base.goBack()
    },
    showToast(message, messageIcon = 'SuccessIcon', messageVariant = 'success', messageText = '') {
      this.$refs.base.showToast(message, messageIcon, messageVariant, messageText)
    },
    showToastError(message) {
      this.$refs.base.showToastError(message)
    },
    showLoading() {
      this.$refs.base.showLoading()
    },
    hideLoading() {
      this.$refs.base.hideLoading()
    },
    checkError(error) {
      this.$refs.base.checkError(error)
    },
  },
}
</script>
